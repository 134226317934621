import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Col, Container, DangerouslySetInnerHtml, Layout, Row } from 'layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import InfoBanner from 'components/InfoBanner';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsPageProps> = ({
  pageContext,
  data: {
    ldsPage: { urls, seo, infoBanner, mainCarousel },
  },
}) => {
  const { title, keywords, description } = seo;
  const { body, slug } = pageContext;
  const slugTitle = slug.replaceAll('-', ' ');

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      <section className="lds-page">
        <Container fluid>
          <Row>
            <Col lg="8" className="lds-page__content">
              <h1>{slugTitle}</h1>
              {body ? <DangerouslySetInnerHtml html={body} className={slug} /> : null}
            </Col>
          </Row>
        </Container>
      </section>
      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query LdsQuery($lang: String, $additionalSlug: String) {
    ldsPage(lang: { eq: $lang }, url: { eq: $additionalSlug }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
    }
  }
`;

export default LdsPage;
